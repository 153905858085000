<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.ban-announce.apply-report"></custom-page-title>

                <p>A pénzintézeteknek elő kell állítaniuk a pénzforgalmi
                    jelzőszámok megnyitásával, változásával, illetve zárásával kapcsolatos aktuális
                    gyűjteményt (.xml kiterjesztésű fájl). Ezt a
                    dokumentumot elektronikus aláírással ellátva e-mail üzenetben kell elküldeni a cégbírósághoz.
                    A jelentésről pár percen belül digitális tértivevény érkezik, amiből kiderül,
                    hogy a jelentésünk megfelelt-e a technikai követelményeknek, illetve
                    tájékoztatást kapunk a jelentés esetleges hibáiról.</p>

                <h2>Jelentés elkészítése</h2>

                <p>A bankszámla-jelentést XML formátumban kell elkészíteni.<br>
                    Ehhez használhatjuk az ingyenesen letölthető és használható <a
                        href="/ban/xmljelentes.exe" target="_blank">XML
                        jelentés</a> nevű programot. Rögzítsük benne a szükséges adatokat, majd
                    mentsük el a kész dokumentumot (főmenü --&gt; File --&gt; Jelentés mentése...).</p>

                <p>Természetes más programmal is elkészíthető a jelentés, de
                    ebben az esetben is meg kell felelni a jelentésre vonatkozó <a
                        href="/ban/XmlPj_v1.1.xsd" target="_blank">XML
                        sémadefiníciónak (XSD)</a>.</p>

                <h2>Jelentés beillesztése, aláírása, elküldése</h2>

                <p>A pénzforgalmi jelentés elkészülte után elektronikus alá
                    kell írni.<br>
                    Indítsuk el az e-Szignót, és ellenőrizzük és válasszuk ki az „Alapértelmezett”
                    sémát:</p>

                <p><img src="@/assets/help/images/image023.jpg"></p>

                <p>A jelentést tartalmazó XML fájlt be kell illesztenünk az
                    üres elektronikus aktánkba. Ehhez válasszuk a főmenüben a
                    &quot;Dokumentum&quot; menüpontot, azon belül a &quot;Beillesztés&quot; opciót:</p>

                <p><img src="@/assets/help/images/image024.jpg"></p>

                <p>A fájlt a Windows rendszereken megszokott módon kereshetjük
                    meg számítógépünk könyvtárszerkezetében: a megnyíló tallózó ablakban
                    kattintsunk rá duplán a kérdéses fájlra, vagyis a kész jelentésre. A dokumentum
                    bekerül az e-aktánkba:</p>

                <p><img src="@/assets/help/images/image025.jpg"></p>

                <p>A rendelkező jogszabály szerint a jelentést cégszerű
                    aláírással kell ellátnunk; ehhez használhatunk akár fokozott akár minősített
                    tanúsítványt. Helyezzük be a digitális aláírásunkat tartalmazó kártyát a
                    kártyaolvasó készülékbe, jelöljük ki az aktastruktúrában a legfelső sort, mely
                    az akta nevét tartalmazza, majd válasszuk a főmenüből az &quot;Aláírás&quot;,
                    utána pedig az &quot;Aláírás készítése&quot; pontot. A művelet végén megjelenik
                    aláírásunk az aktastruktúra végén:</p>

                <p><img src="@/assets/help/images/image026.jpg"></p>

                <p>Az aláírás elkészítése után mentsük elektronikus aktánkat
                    (főmenü --&gt; E-akta --&gt; E-akta mentése), majd küldjük el a <a href="mailto:szamlaszam@mediator.e-cegjegyzek.hu">szamlaszam@mediator.e-cegjegyzek.hu</a> címre
                    (főmenü --&gt; E-akta --&gt; E-akta küldése levélként):</p>

                <p><img src="@/assets/help/images/image027.jpg"></p>

                <h2>Válaszüzenet</h2>

                <p>A jelentés elküldését követően rövid időn belül válaszüzenet
                    érkezik, melynek tárgy sorában &quot;Digitális tértivevény&quot; szerepel.</p>

                <p>Ha valamiért a számítógépes feldolgozó rendszer nem tudja az
                    igazolást elsőre elküldeni, például a levelezőrendszer átmeneti hibás működése
                    miatt, még további két alkalommal megkísérli azt; ha három küldés sem hoz
                    eredményt, a tértivevény nem kerül kiküldésre. Ha nem kapott visszaigazolást,
                    ismételje meg a küldést még egyszer, és ha ez sem hoz eredményt, lépjen
                    kapcsolatba ügyfélszolgálatunkkal. (Kétszer nem tud bekerülni a cégjegyzékbe
                    ugyanaz a bankszámlaszám, így az ismételt küldéssel nem tud hibát okozni.)</p>

                <h2>A tértivevény ellenőrzése</h2>

                <p>A jelentés elküldése után a rendszer elektronikus átvételi
                    elismervényt (tértivevényt) küld. A tértivevény igazolja, hogy a Céginformációs Szolgálat
                    átvette a jelentést.</p>

                <p>A tértivevényt nyissuk meg az e-Szignó programmal és
                    kattintsunk kétszer az &quot;Informatikai vizsgálat eredménye&quot;
                    dokumentumra.</p>


                <p><img src="@/assets/help/images/image028.jpg"></p>

                <p>Ha a vizsgálat rendben találta a jelentést, a rajta lévő
                    aláírást, és a benne küldött adatokat, akkor ilyen tértivevényt kell kapnunk.</p>


                <p><img src="@/assets/help/images/image029.png"></p>

                <h2>Formai hibák</h2>

                <p>Ha a jelentés szintaktikusan hibás, akkor a „Hibajegyzék”
                    részben látható, pontosan melyik adatok nem megfelelőek.</p>

                <p>A szintaktikai hiba azt jelenti, hogy a XML formátumú
                    jelentés nem felel meg a sémadefiníciónak, ezért nem dolgozható fel
                    automatikusan. Pl. ilyen hiba lehet, hogy a jelentés valamelyik eleme hiányzik
                    vagy rossz helyre került, nem adtak meg minden kötelező adatot, vagy van olyan
                    cégjegyzékszám a jelentésben, ami nem 10 számjegyű.</p>

                <p>Teendő: Ha az <a
                    href="/ban/xmljelentes.exe" target="_blank">Xmljelentes</a>
                    programot használják, akkor adjanak meg minden szükséges adatot.<br>
                    Ha saját fejlesztésű program generálja a jelentést, akkor a program
                    fejlesztőjét értesítsék, hogy a generált jelentés nem felel meg a
                    sémadefiníciónak.</p>


                <p>Ha a jelentés nem megengedett formátumú dokumentumot
                    tartalmaz, pl. egy Excel táblát küldenek el az XML formátumú jelentés helyett,
                    akkor a tértivevényben az alábbi figyelmeztető szöveget látjuk:</p>

                <p style="color: red;">A(z) e-akta formátumhibás dokumentumot tartalmaz. A hibás
                    dokumentum a &quot;Dokumentum formátuma (kiterjesztése)&quot; sorban pirossal
                    szerepel.</p>


                <p>Ha a jelentést nem az Alapértelmezett sémát használva
                    készítjük el, akkor az informatikai vizsgálat eredménye:</p>

                <p style="color: red;">A dokumentum (540-060717.xml) szintaktikusan hibás!</p>


                <p>Ha az aláírás nem felel meg a követelményeknek, például nem
                    minősített elektronikus aláírással vagy fokozott biztonságú aláírással küldjük
                    be a jelentést, hanem egy teszt tanúsítvánnyal, akkor a rendszer a jelentésen
                    szereplő aláírást érvénytelennek tekinti</p>

                <p style="color: red;">Az aláírás érvénytelen vagy nem ellenőrizhető.</p>


                <p>Ha a tértivevény szerint az informatikai vizsgálat
                    valamilyen formai hibát talált a jelentésben, akkor javítsuk ki a hibát, és a
                    jelentést küldjük el ismételten.<br>
                    Formai hiba esetén a jelentésben szereplő adatok egyáltalán nem kerülnek
                    feldolgozásra!</p>

                <h2>Tartalmi hibák</h2>

                <p>A tartalmi hibákat az &quot;Informatikai vizsgálat
                    eredménye&quot; Hibajegyzék része tartalmazza. A hibajegyzék egy-egy sorában
                    egy lejelentett bankszámlához tartozó adatok találhatók, pirossal megjelölve a
                    hibás adatot.</p>

                <h3>Cégjegyzékszám</h3>

                <p>A megadott cégjegyzékszám hibás, vagy nincs ilyen
                    cégjegyzékszámú cég.</p>

                <div>Ellenőrizze a következőket:
                    <ul>

                        <li>Pontosan adta-e meg a cégjegyzékszámot.</li>

                        <li>A cégjegyzékszám első 2 számjegyének a 01-20 tartományba
                            kell esnie.
                        </li>

                        <li>A cégjegyzékszám második 2 számjegyének a 01-23 tartományba
                            kell esnie.
                        </li>
                    </ul>
                </div>
                <p></p>

                <h3>Törzsszám (az adószám első 8 számjegye)</h3>

                <p>A megadott törzsszám hibás, vagy nem stimmel a
                    törzsszám-cégjegyzékszám páros, vagy a törzsszámot már törölték a cég
                    cégjegyzékéből.</p>

                <div>Ellenőrizze a következőket:
                    <ul>

                        <li>Pontosan adta-e meg a törzsszámot.</li>

                        <li>Megszűnt-e a cég (felszámolás, végelszámolás miatt)?<br>
                            Ebben az esetben nincs további teendője, mert a cég törlésével a cégbíróság az
                            összes adatát, így a bankszámláit is törölte a cégjegyzékből.<br>
                            Új bankszámla bejelentésére ilyenkor már nincs lehetőség.
                        </li>

                        <li>Átalakult-e a cég?<br>
                            Ebben az esetben a cégbíróság törölte a cég összes adatát a cégjegyzékből, így
                            a bankszámláit is.<br>
                            Számlanyitás vagy az átalakulás időpontja után történt számlazárás esetén a
                            jogutód cég cégjegyzékszámával kell elküldeni a jelentést.<br>
                            Az átalakulás időpontja előtti számlazárás esetén nincs teendő, a cég
                            törlésével a cégbíróság az összes adatát, így a bankszámláit is törölte a
                            cégjegyzékből.
                        </li>

                        <li>Áthelyezte-e a cég a székhelyét egy másik megyébe?<br>
                            Ebben az esetben a cég új cégjegyzékszámot kapott, és ezzel kell elküldeni a
                            jelentést.
                        </li>
                    </ul>
                </div>

                <p>A fenti esetekben a cégeknek elvileg le kell jelentenie a
                    cégjegyzékszám változást. Ha ezt a cégek nem tették meg, akkor kérjük az online
                    céginformációs rendszerben nézzenek utána a változásnak. Felhívjuk figyelmüket,
                    hogy a cd-n kiadott cégadatbázisok nem naprakészek, a
                    kiadásuk óta megtörtént változásokat nem tartalmazzák.</p>

                <h3>Bankszámlaszám</h3>


                <p>A megadott bankszámlaszámmal van gond.</p>

                <p>Ellenőrizze, hogy lejelentették-e a bankszámlaszámot nyitó
                    fiókot.<br>
                    Ha nem, akkor jelentsék le először a fiókot, majd jelentsék újra a
                    bankszámlaszámot.</p>

                <h3>Záró dátum</h3>

                <p>A záró dátum hibás, vagy nem is lehetett volna megadni.</p>

                <div>Ellenőrizze a következőket:
                    <ul>
                        <li>Törlés esetén a záró dátum nem lehet korábbi a nyitó
                            dátumnál.
                        </li>

                        <li>Nyitás és módosítás esetén nem lehet záró dátumot megadni.</li>
                    </ul>
                </div>
                <p></p>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpBanAnnounceApplyReport extends Vue {
    data() {
        return {
            publicPath: process.env.BASE_URL
        }
    }

}

</script>

<style scoped>

</style>